import LoginButton from "components/utils/loginButtons/LoginButton"
import getpackedIcon from "images/login/getpacked.svg"

interface GetpackedLoginButtonProps {
  onClick: () => void
  disabled?: boolean
  signUp?: boolean
  reset?: boolean
}
const GetpackedLoginButton = ({ onClick, disabled, signUp, reset }: GetpackedLoginButtonProps) => {
  return (
    <LoginButton
      disabled={disabled}
      icon={getpackedIcon}
      text={signUp ? "Jetzt registrieren" : reset ? "Passwort zurücksetzen" : "Jetzt anmelden"}
      onClick={onClick}
    />
  )
}

export default GetpackedLoginButton
