import GlobalStyles from "components/styles/GlobalStyles"
import CommonHead from "components/utils/CommonHead"
import { NextPage } from "next"
import { AppProps } from "next/dist/shared/lib/router/router"
import { ReactElement, ReactNode } from "react"
import { ToastProvider } from "react-toast-notifications"
import { ThemeProvider } from "styled-components"
import { mainTheme } from "styles/styledComponentsTheme"
import { useInitialize } from "util-functions/init"

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  useInitialize()

  const getLayout = Component.getLayout ?? (page => page)

  return (
    <>
      <CommonHead />
      <GlobalStyles />
      <ToastProvider placement="top-center">
        <ThemeProvider theme={mainTheme}>{getLayout(<Component {...pageProps} />)}</ThemeProvider>
      </ToastProvider>
    </>
  )
}

export default MyApp
