import AppleLoginButton from "components/utils/loginButtons/AppleLoginButton"
import GoogleLoginButton from "components/utils/loginButtons/GoogleLoginButton"
import MicrosoftLoginButton from "components/utils/loginButtons/MicrosoftLoginButton"

const SocialLoginButtons = () => {
  return (
    <>
      <GoogleLoginButton />
      <AppleLoginButton />
      <MicrosoftLoginButton />
    </>
  )
}

export default SocialLoginButtons
