import { useUser } from "hooks/firestore/simple/useUser"
import { useCurrentUser } from "hooks/localstate/auth/useCurrentUser"
import useDebounce from "hooks/misc/useDebounce"
import { useLastShop } from "hooks/misc/useLastShop"
import { useRouter } from "next/router"
import { useEffect } from "react"
import { UrlObject } from "url"

export const useRedirectLoggedInUsers = (replace?: boolean) => {
  const router = useRouter()

  const push = (target: UrlObject) =>
    router.pathname !== target.pathname && (replace ? router.replace(target) : router.push(target))
  const user = useUser()
  const lastShop = useLastShop()
  const currentUser = useCurrentUser()
  const redirectDebounce = useDebounce(!!currentUser, 1000)
  useEffect(() => {
    if (currentUser) {
      if (!currentUser.emailVerified) {
        push({ pathname: "/email-verification", query: lastShop ? { shop: lastShop } : {} })
        return
      }
      if (user?.currentRestaurant) {
        push({ pathname: "/admin" })
        return
      }
      if (lastShop) {
        push({ pathname: `/${lastShop}` })
        return
      }
      if (redirectDebounce) {
        push({ pathname: "/admin" })
        return
      }
    }
  }, [user, currentUser, lastShop, redirectDebounce])
}
