import { collection, CollectionReference, doc, DocumentReference, getFirestore } from "firebase/firestore"
import { genericConverter, WithRef } from "hooks/firestore/FirestoreDocument"
import Basket from "types/firestore/basket"
import { Category } from "types/firestore/category"
import DiscountCode from "types/firestore/discountCode"
import { DistributionOption } from "types/firestore/distributionOption"
import { GlobalStats } from "types/firestore/globalStats"
import { Item } from "types/firestore/item"
import { Offering } from "types/firestore/offering"
import { Option } from "types/firestore/option"
import { Order } from "types/firestore/order"
import PaymentSettings from "types/firestore/paymentSettings"
import PrivateUser from "types/firestore/privateUser"
import ProductGroup from "types/firestore/productGroup"
import Settings from "types/firestore/settings"
import Shop from "types/firestore/shop"
import ShortUrl from "types/firestore/shorturl"
import { Stat } from "types/firestore/stat"
import Success from "types/firestore/success"
import User from "types/firestore/user"

function getConvertedCollection<T>(path: string) {
  const ref = collection(getFirestore(), path)
  return ref.withConverter(genericConverter<T>())
}

function getConvertedDocument<T>(path: string) {
  const ref = doc(getFirestore(), path)
  return ref.withConverter(genericConverter<T>())
}

// ts-prune-ignore-next
export function getCategoriesRef(offeringId: string, storefrontId: string): CollectionReference<WithRef<Category>>
export function getCategoriesRef(
  offeringId: string | undefined,
  storefrontId: string | undefined
): undefined | CollectionReference<WithRef<Category>>
export function getCategoriesRef(offeringId: string | undefined, storefrontId: string | undefined) {
  return offeringId && storefrontId
    ? getConvertedCollection<Category>(`offerings/${offeringId}/storefronts/${storefrontId}/categories`)
    : undefined
}

// ts-prune-ignore-next
export function getCategoryRef(
  offeringId: string,
  storefrontId: string,
  categoryId: string
): DocumentReference<WithRef<Category>>
export function getCategoryRef(
  offeringId: string | undefined,
  storefrontId: string | undefined,
  categoryId: string | undefined
): undefined | DocumentReference<WithRef<Category>>
export function getCategoryRef(
  offeringId: string | undefined,
  storefrontId: string | undefined,
  categoryId: string | undefined
) {
  return offeringId && storefrontId && categoryId
    ? getConvertedDocument<Category>(`offerings/${offeringId}/storefronts/${storefrontId}/categories/${categoryId}`)
    : undefined
}

// ts-prune-ignore-next
export function getProductGroupsRef(
  offeringId: string,
  storefrontId: string
): CollectionReference<WithRef<ProductGroup>>
export function getProductGroupsRef(
  offeringId: string | undefined,
  storefrontId: string | undefined
): undefined | CollectionReference<WithRef<ProductGroup>>
export function getProductGroupsRef(offeringId: string | undefined, storefrontId: string | undefined) {
  return offeringId && storefrontId
    ? getConvertedCollection<ProductGroup>(`offerings/${offeringId}/storefronts/${storefrontId}/productGroups`)
    : undefined
}

// ts-prune-ignore-next
export function getProductGroupRef(
  offeringId: string,
  storefrontId: string,
  productGroupId: string
): DocumentReference<WithRef<ProductGroup>>
export function getProductGroupRef(
  offeringId: string | undefined,
  storefrontId: string | undefined,
  productGroupId: string | undefined
): undefined | DocumentReference<WithRef<ProductGroup>>
export function getProductGroupRef(
  offeringId: string | undefined,
  storefrontId: string | undefined,
  productGroupId: string | undefined
) {
  return offeringId && storefrontId && productGroupId
    ? getConvertedDocument<ProductGroup>(
        `offerings/${offeringId}/storefronts/${storefrontId}/productGroups/${productGroupId}`
      )
    : undefined
}

// ts-prune-ignore-next
export function getOptionsRef(offeringId: string): CollectionReference<WithRef<Option>>
export function getOptionsRef(offeringId: string | undefined): undefined | CollectionReference<WithRef<Option>>
export function getOptionsRef(offeringId: string | undefined) {
  return offeringId ? getConvertedCollection<Option>(`offerings/${offeringId}/options`) : undefined
}

// ts-prune-ignore-next
export function getOptionRef(offeringId: string, optionId: string): DocumentReference<WithRef<Option>>
export function getOptionRef(
  offeringId: string | undefined,
  optionId: string | undefined
): undefined | DocumentReference<WithRef<Option>>
export function getOptionRef(offeringId: string | undefined, optionId: string | undefined) {
  return offeringId && optionId
    ? getConvertedDocument<Option>(`offerings/${offeringId}/options/${optionId}`)
    : undefined
}

// ts-prune-ignore-next
export function getItemsRef(offeringId: string): CollectionReference<WithRef<Item>>
export function getItemsRef(offeringId: string | undefined): undefined | CollectionReference<WithRef<Item>>
export function getItemsRef(offeringId: string | undefined) {
  return offeringId ? getConvertedCollection<Item>(`offerings/${offeringId}/items`) : undefined
}

// ts-prune-ignore-next
export function getItemRef(offeringId: string, itemId: string): DocumentReference<WithRef<Item>>
export function getItemRef(
  offeringId: string | undefined,
  itemId: string | undefined
): undefined | DocumentReference<WithRef<Item>>
export function getItemRef(offeringId: string | undefined, itemId: string | undefined) {
  return offeringId && itemId ? getConvertedDocument<Item>(`offerings/${offeringId}/items/${itemId}`) : undefined
}

// ts-prune-ignore-next
export function getOfferingsRef() {
  return getConvertedCollection<Offering>(`offerings`)
}

// ts-prune-ignore-next
export function getOfferingRef(offeringId: string): DocumentReference<WithRef<Offering>>
export function getOfferingRef(offeringId: string | undefined): undefined | DocumentReference<WithRef<Offering>>
export function getOfferingRef(offeringId: string | undefined) {
  return offeringId ? getConvertedDocument<Offering>(`offerings/${offeringId}`) : undefined
}

// ts-prune-ignore-next
export function getStatsRef(shopId: string): CollectionReference<WithRef<Stat>>
export function getStatsRef(shopId: string | undefined): undefined | CollectionReference<WithRef<Stat>>
export function getStatsRef(shopId: string | undefined) {
  return shopId ? getConvertedCollection<Stat>(`restaurants/${shopId}/stats`) : undefined
}

// ts-prune-ignore-next
export function getStatRef(shopId: string, statId: string): DocumentReference<WithRef<Stat>>
export function getStatRef(
  shopId: string | undefined,
  statId: string | undefined
): undefined | DocumentReference<WithRef<Stat>>
export function getStatRef(shopId: string | undefined, statId: string | undefined) {
  return shopId && statId ? getConvertedDocument<Stat>(`restaurants/${shopId}/stats/${statId}`) : undefined
}

// ts-prune-ignore-next
export function getOrdersRef(shopId: string): CollectionReference<WithRef<Order>>
export function getOrdersRef(shopId: string | undefined): undefined | CollectionReference<WithRef<Order>>
export function getOrdersRef(shopId: string | undefined) {
  return shopId ? getConvertedCollection<Order>(`restaurants/${shopId}/orders`) : undefined
}

// ts-prune-ignore-next
export function getOrderRef(shopId: string, orderId: string): DocumentReference<WithRef<Order>>
export function getOrderRef(
  shopId: string | undefined,
  orderId: string | undefined
): undefined | DocumentReference<WithRef<Order>>
export function getOrderRef(shopId: string | undefined, orderId: string | undefined) {
  return shopId && orderId ? getConvertedDocument<Order>(`restaurants/${shopId}/orders/${orderId}`) : undefined
}

// ts-prune-ignore-next
export function getDistributionOptionsRef(shopId: string): CollectionReference<WithRef<DistributionOption>>
export function getDistributionOptionsRef(
  shopId: string | undefined
): undefined | CollectionReference<WithRef<DistributionOption>>
export function getDistributionOptionsRef(shopId: string | undefined) {
  return shopId ? getConvertedCollection<DistributionOption>(`restaurants/${shopId}/distributionOptions`) : undefined
}

// ts-prune-ignore-next
export function getDistributionOptionRef(
  shopId: string,
  distributionOptionId: string
): DocumentReference<WithRef<DistributionOption>>
export function getDistributionOptionRef(
  shopId: string | undefined,
  distributionOptionId: string | undefined
): undefined | DocumentReference<WithRef<DistributionOption>>
export function getDistributionOptionRef(shopId: string | undefined, distributionOptionId: string | undefined) {
  return shopId && distributionOptionId
    ? getConvertedDocument<DistributionOption>(`restaurants/${shopId}/distributionOptions/${distributionOptionId}`)
    : undefined
}
// ts-prune-ignore-next
export function getBasketsRef(shopId: string): CollectionReference<WithRef<Basket>>
export function getBasketsRef(shopId: string | undefined): undefined | CollectionReference<WithRef<Basket>>
export function getBasketsRef(shopId: string | undefined) {
  return shopId ? getConvertedCollection<Basket>(`restaurants/${shopId}/baskets`) : undefined
}

// ts-prune-ignore-next
export function getBasketRef(shopId: string, basketId: string): DocumentReference<WithRef<Basket>>
export function getBasketRef(
  shopId: string | undefined,
  basketId: string | undefined
): undefined | DocumentReference<WithRef<Basket>>
export function getBasketRef(shopId: string | undefined, basketId: string | undefined) {
  return shopId && basketId ? getConvertedDocument<Basket>(`restaurants/${shopId}/baskets/${basketId}`) : undefined
}

// ts-prune-ignore-next
export function getShopsRef() {
  return getConvertedCollection<Shop>(`restaurants`)
}

// ts-prune-ignore-next
export function getShopRef(shopId: string): DocumentReference<WithRef<Shop>>
export function getShopRef(shopId: string | undefined): undefined | DocumentReference<WithRef<Shop>>
export function getShopRef(shopId: string | undefined): undefined | DocumentReference<WithRef<Shop>> {
  return shopId ? getConvertedDocument<Shop>(`restaurants/${shopId}`) : undefined
}

// ts-prune-ignore-next
export function getSettingsRef(shopId: string): DocumentReference<WithRef<Settings>>
export function getSettingsRef(shopId: string | undefined): undefined | DocumentReference<WithRef<Settings>>
export function getSettingsRef(shopId: string | undefined) {
  return shopId ? getConvertedDocument<Settings>(`restaurants/${shopId}/data/settings`) : undefined
}

// ts-prune-ignore-next
export function getSuccessRef(shopId: string): DocumentReference<WithRef<Success>>
export function getSuccessRef(shopId: string | undefined): undefined | DocumentReference<WithRef<Success>>
export function getSuccessRef(shopId: string | undefined) {
  return shopId ? getConvertedDocument<Success>(`restaurants/${shopId}/data/success`) : undefined
}

// ts-prune-ignore-next
export function getPaymentSettingsRef(shopId: string): DocumentReference<WithRef<PaymentSettings>>
export function getPaymentSettingsRef(
  shopId: string | undefined
): undefined | DocumentReference<WithRef<PaymentSettings>>
export function getPaymentSettingsRef(shopId: string | undefined) {
  return shopId ? getConvertedDocument<PaymentSettings>(`restaurants/${shopId}/data/paymentSettings`) : undefined
}

// ts-prune-ignore-next
export function getShortUrlsRef() {
  return getConvertedCollection<ShortUrl>(`shorturls`)
}

// ts-prune-ignore-next
export function getShortUrlRef(shortUrlId: string): DocumentReference<WithRef<ShortUrl>>
export function getShortUrlRef(shortUrlId: string | undefined): undefined | DocumentReference<WithRef<ShortUrl>>
export function getShortUrlRef(shortUrlId: string | undefined) {
  return shortUrlId ? getConvertedDocument<ShortUrl>(`shorturls/${shortUrlId}`) : undefined
}

// ts-prune-ignore-next
export function getUsersRef() {
  return getConvertedCollection<User>(`users`)
}

// ts-prune-ignore-next
export function getUserRef(userId: string): DocumentReference<WithRef<User>>
export function getUserRef(userId: string | undefined): undefined | DocumentReference<WithRef<User>>
export function getUserRef(userId: string | undefined) {
  return userId ? getConvertedDocument<User>(`users/${userId}`) : undefined
}

// ts-prune-ignore-next
export function getPrivateUserRef(userId: string): DocumentReference<WithRef<PrivateUser>>
export function getPrivateUserRef(userId: string | undefined): undefined | DocumentReference<WithRef<PrivateUser>>
export function getPrivateUserRef(userId: string | undefined) {
  return userId ? getConvertedDocument<PrivateUser>(`users/${userId}/private/user`) : undefined
}

// ts-prune-ignore-next
export function getGlobalStatsRef() {
  return getConvertedDocument<GlobalStats>(`data/stats`)
}

// ts-prune-ignore-next
export function getDiscountCodesRef(shopId: string): CollectionReference<WithRef<DiscountCode>>
export function getDiscountCodesRef(shopId: string | undefined): undefined | CollectionReference<WithRef<DiscountCode>>
export function getDiscountCodesRef(shopId: string | undefined) {
  return shopId ? getConvertedCollection<DiscountCode>(`restaurants/${shopId}/discountCodes`) : undefined
}

// ts-prune-ignore-next
export function getDiscountCodeRef(shopId: string, discountCodeId: string): DocumentReference<WithRef<DiscountCode>>
export function getDiscountCodeRef(
  shopId: string | undefined,
  discountCodeId: string | undefined
): undefined | DocumentReference<WithRef<DiscountCode>>
export function getDiscountCodeRef(shopId: string | undefined, discountCodeId: string | undefined) {
  return shopId && discountCodeId
    ? getConvertedDocument<DiscountCode>(`restaurants/${shopId}/discountCodes/${discountCodeId}`)
    : undefined
}
