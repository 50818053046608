import * as publicSecrets from "./publicSecrets.json"

interface Firebase {
  apiKey: string
  authDomain: string
  databaseURL: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  appId: string
  measurementId: string
}

interface Google {
  placesKey: string
}

interface Emulator {
  host: string
  firestorePort?: number
  authPort?: number
  functionsPort?: number
  storagePort?: number
}

interface Intercom {
  appId: string
}

interface Algolia {
  appId: string
  indexPre: string
}

interface Stripe {
  clientId: string
  key: string
}

interface Paypal {
  clientId: string
}

interface Settings {
  appUrl: string
  functionsUrl: string
  version: string
}

interface Integromat {
  environment: string
}

interface Sentry {
  appDsn: string
}

interface PrivateServiceAccount {
  type: string
  project_id: string
  private_key: string
  private_key_id: string
  client_email: string
  client_id: string
  auth_uri: string
  token_uri: string
  auth_provider_x509_cert_url: string
  client_x509_cert_url: string
}

interface PrivateSentry extends Sentry {
  functionsDsn: string
}

interface PrivateAlgolia extends Algolia {
  adminKey: string
}

interface PrivateSendgrid {
  apiKey: string
}

interface PrivateFirebase extends Firebase {
  backupBucket: string
}

interface PrivateStripe extends Stripe {
  secret: string
  creditPurchaseWebhookSecret: string
  paymentMethodChangeWebhookSecret: string
  invoicePaymentWebhookSecret: string
  successfulPurchaseWebhookSecret: string
  taxRateKey: string
  subscriptionPriceKey: string
  subscriptionItemPriceKey: string
}

interface PrivateIntercom extends Intercom {
  ivSecret: string
  accessToken: string
}

interface PrivatePrerender {
  token: string
}
interface Cookiebot {
  id: string
}

type PrivateIntegromat = Integromat

interface PrivatePaypal extends Paypal {
  clientSecret: string
  mode: string
}

interface PrivateNineOne {
  token: string
}

export interface PublicSecrets {
  firebase: Firebase
  paypal: Paypal
  stripe: Stripe
  algolia: Algolia
  intercom: Intercom
  sentry?: Sentry
  integromat: Integromat
  settings: Settings
  emulator?: Emulator
  google: Google
  cookiebot: Cookiebot
}

export interface PrivateSecrets {
  paypal: PrivatePaypal
  algolia: PrivateAlgolia
  serviceAccount: PrivateServiceAccount
  sendgrid: PrivateSendgrid
  firebase: PrivateFirebase
  stripe: PrivateStripe
  intercom: PrivateIntercom
  sentry?: PrivateSentry
  prerender: PrivatePrerender
  integromat: PrivateIntegromat
  nineOne: PrivateNineOne
  settings: Settings
  emulator?: Emulator
  google: Google
  cookiebot: Cookiebot
}

export const secrets: PublicSecrets = process.env.NEXT_PUBLIC_SECRETS
  ? require(process.env.NEXT_PUBLIC_SECRETS || "./publicSecrets.json")
  : publicSecrets
