import classNames from "classnames"
import { FunctionComponent, useState } from "react"

interface PatternPageContainerProps {
  light?: boolean
}

const PatternPageContainer: FunctionComponent<PatternPageContainerProps> = ({ light, children }) => {
  const [mousePos, setMousePos] = useState([-500, -500])
  return (
    <>
      <style jsx>{`
        .patternDiv {
          position: absolute;
          height: 100vh;
          width: 100vw;
          overflow-x: hidden;

          background-repeat: no-repeat, repeat, repeat;
          background-size: 100% 100%, 30px 30px, 30px 30px;
          background-position: 0 0, 0 0, 15px 15px;
        }
        .dark {
          background-image: radial-gradient(
              circle 30vh at ${mousePos[0]}px ${mousePos[1]}px,
              rgba(63, 94, 251, 0) 0%,
              rgba(0, 0, 0, 0.8) 100%
            ),
            radial-gradient(#888888 1px, transparent 1px), radial-gradient(#888888 1px, #0d0d0d 1px);
        }
        .light {
          background-image: radial-gradient(
              circle 30vh at ${mousePos[0]}px ${mousePos[1]}px,
              rgba(255, 255, 255, 0.5) 0%,
              rgba(255, 255, 255, 0.91) 100%
            ),
            radial-gradient(#000000 1px, transparent 1px), radial-gradient(#000000 1px, #ffffff 1px);
          background-blend-mode: lighten, normal;
        }
      `}</style>

      <div
        className={classNames({ patternDiv: true, light: light, dark: !light })}
        onTouchMove={e =>
          e.targetTouches[0]?.clientX &&
          e.targetTouches[0]?.clientY &&
          setMousePos([e.targetTouches[0]?.clientX, e.targetTouches[0]?.clientY])
        }
        onMouseMove={e => setMousePos([e.clientX, e.clientY])}
      >
        {children}
      </div>
    </>
  )
}

export default PatternPageContainer
