import { startLoginWithEmail } from "actions/authFunctions"
import InputField from "components/utils/InputField"
import GetpackedLoginButton from "components/utils/loginButtons/GetpackedLoginButton"
import { useEffect, useState } from "react"

interface EmailLoginFormProps {
  defaultEmail?: string
  defaultPassword?: string
  trySigninWithDefaults?: boolean
}

const EmailLoginForm = ({ defaultEmail, defaultPassword, trySigninWithDefaults }: EmailLoginFormProps) => {
  const [email, setEmail] = useState(defaultEmail || "")
  const [password, setPassword] = useState(defaultPassword || "")
  const [error, setError] = useState<undefined | "email" | "password" | "other">(undefined)

  const errorMessage =
    error &&
    {
      email: "Diese E-Mail ist uns nicht bekannt",
      password: "Das angegebene Passwort ist falsch",
      other: "Ein Fehler ist aufgetreten, versuche es nachher nochmal",
    }[error]

  const handleLogin = async () => {
    setError(undefined)
    try {
      await startLoginWithEmail(email, password)
    } catch (error) {
      if (error) {
        console.error(error)
        setError(
          (error as Error).message.includes("auth/wrong-password")
            ? "password"
            : (error as Error).message.includes("auth/user-not-found")
            ? "email"
            : "other"
        )
      }
    }
  }

  useEffect(() => {
    if (defaultEmail && defaultPassword && trySigninWithDefaults) {
      handleLogin()
    }
  }, [defaultEmail, defaultPassword, trySigninWithDefaults])

  return (
    <>
      {errorMessage && <p>{errorMessage}</p>}
      <InputField danger={error === "email"} type="email" value={email} onChange={e => setEmail(e.target.value)}>
        E-Mail Adresse
      </InputField>
      <InputField
        danger={error === "password"}
        type="password"
        value={password}
        onChange={e => setPassword(e.target.value)}
      >
        Passwort
      </InputField>
      <GetpackedLoginButton onClick={handleLogin} disabled={!email || !password} />
    </>
  )
}

export default EmailLoginForm
