import { FunctionComponent } from "react"

const VerticalDivider: FunctionComponent = ({ children }) => {
  return (
    <>
      <style jsx>{`
        .divider {
          width: auto;
          font-size: 12px;
          text-transform: uppercase;
          text-align: center;
          margin: 0;
          font-weight: 600;
          position: relative;
          justify-self: center;
        }

        .divider::before {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: calc(100% + 10px);
          width: 100vw;
          height: 1px;
          background-color: currentColor;
          opacity: 0.5;
        }

        .divider::after {
          right: unset;
          left: calc(100% + 10px);
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: calc(100% + 10px);
          width: 100vw;
          height: 1px;
          background-color: currentColor;
          opacity: 0.5;
        }
      `}</style>
      <span className="divider">{children}</span>
    </>
  )
}

export default VerticalDivider
