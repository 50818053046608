import Image, { ImageProps } from "next/image"
import styles from "./LoginButton.module.scss"

interface LoginButtonProps {
  icon?: ImageProps["src"]
  text: string
  onClick: () => void
  disabled?: boolean
}

const LoginButton = ({ icon, text, onClick, disabled }: LoginButtonProps) => {
  return (
    <button disabled={disabled} className={styles.loginButton} onClick={onClick}>
      {icon ? (
        <Image src={icon} alt={`${text} logo`} layout="fixed" height={26} width={26}></Image>
      ) : (
        <div style={{ width: 26 }} />
      )}
      <span className={styles.buttonText}>{text}</span>
    </button>
  )
}

export default LoginButton
