import React, { useEffect, useRef, useState } from "react"
import styled, { css } from "styled-components"

interface PlaceholderBackgroundDivProps {
  readonly dimmed?: boolean
}

const PlaceholderBackgroundDiv = styled.div<PlaceholderBackgroundDivProps>`
  text-align: left;
  position: absolute;
  ${props =>
    props.dimmed &&
    css`
      opacity: 0.5;
    `};
  pointer-events: none;
`

interface InputFieldForPlaceholdersProps {
  readonly hideValue?: boolean
}

const InputFieldForPlaceholders = styled.input<InputFieldForPlaceholdersProps>`
  min-width: 5rem;
  ${props =>
    props.hideValue
      ? css`
          color: rgba(0, 0, 0, 0);
        `
      : css``}
`

interface CleverCurrencyFieldProps {
  readonly placeholder?: string
  readonly disabled?: boolean
  readonly name?: string
  readonly required?: boolean
  readonly value?: string
  readonly onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  readonly onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  readonly onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  readonly defaultValue?: string
  readonly id?: string
}

const CleverCurrencyField = ({
  placeholder,
  disabled,
  name,
  required,
  value,
  onChange,
  onBlur,
  onFocus,
  defaultValue,
  id,
}: CleverCurrencyFieldProps) => {
  const [focussed, setFocussed] = useState(false)
  const fieldRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (fieldRef.current && focussed) {
      fieldRef.current.select()
      fieldRef.current.focus()
    }
  }, [focussed])

  const [internalValue, setInternalValue] = useState(String(value))

  const cleanNumberString = (text: string) => {
    if (!text) {
      return undefined
    }
    text = text.replace(",", ".")
    const fractionLength = text.split(".")?.[1]?.length ?? -1
    const fixedTwo = parseFloat(text.slice(0, text.length - fractionLength + 2)).toFixed(2)
    return fixedTwo.slice(0, fixedTwo.length + fractionLength - 2)
  }

  const change = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nativeEvent = e.nativeEvent as InputEvent

    const cleanedNumber =
      nativeEvent.data === "-"
        ? internalValue.charAt(0) === "-"
          ? internalValue.slice(1)
          : "-" + internalValue
        : cleanNumberString(e.target.value) || "0"

    setInternalValue(cleanedNumber)
    e.target.value = cleanedNumber
    return onChange && onChange(e)
  }

  const placeholderFormat = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    useGrouping: false,
    significantDigits: 2,
  } as Intl.NumberFormatOptions)

  return (
    <>
      <PlaceholderBackgroundDiv className="placeholder" dimmed={focussed} onClick={() => setFocussed(true)}>
        {placeholderFormat.format(parseFloat(internalValue))}
      </PlaceholderBackgroundDiv>
      <InputFieldForPlaceholders
        inputMode="decimal"
        pattern="[0-9,.-]*"
        id={id}
        hideValue={!focussed}
        ref={fieldRef}
        type="text"
        placeholder={placeholder}
        disabled={disabled}
        name={name}
        required={required}
        value={internalValue}
        onChange={change}
        onBlur={e => {
          setFocussed(false)
          onBlur && onBlur(e)
        }}
        onFocus={e => {
          setFocussed(true)
          onFocus && onFocus(e)
        }}
        onKeyDown={e => {
          if (e.key === "Enter") {
            // @ts-expect-error idk, this should work
            document?.activeElement?.blur()
          }
        }}
        defaultValue={defaultValue}
      />
    </>
  )
}

interface TextfieldContainerProps {
  readonly danger?: boolean
  readonly dense?: boolean
  readonly color?: string
}

const TextfieldContainer = styled.div<TextfieldContainerProps>`
  position: relative;
  justify-self: stretch;

  & label {
    position: absolute;
    font-size: 1rem;
    left: 0;
    top: 1.5rem;
    transform: translateY(-50%);
    background-color: white;
    color: ${props => (props.danger && props.theme.danger) || "var(--dark)"};
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    transition: 0.1s ease-out;
    transform-origin: left top;
    pointer-events: none;
    border-radius: 5px;
  }

  & input,
  textarea,
  .placeholder {
    appearance: none;
    width: 100%;
    font-size: 1rem;
    outline: none;
    border: 1px solid ${props => (props.danger && props.theme.danger) || props.color || "var(--dark)"};
    border-radius: 10px;
    padding: 0.75rem 0.7rem;
    transition: 0.1s ease-out;
    ${props =>
      props.dense &&
      css`
        padding: 0.25rem 0.7rem;
      `};
    &:focus {
      border-color: var(--primary);
    }
    &:focus + label {
      color: var(--primary);
      top: 0;
      transform: translateY(-50%) scale(0.9);
    }
    &:not(:placeholder-shown) + label {
      top: 0;
      transform: translateY(-50%) scale(0.9);
    }
  }
`

interface InputProps {
  readonly id?: string
  readonly placeholder?: string
  readonly autoComplete?: string
  readonly area?: boolean
  readonly disabled?: boolean
  readonly name?: string
  readonly required?: boolean
  readonly value?: string | number
  readonly children?: React.ReactNode
  readonly onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  readonly onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  readonly onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  readonly defaultValue?: string
  readonly type?: string
  readonly rows?: number
  readonly dense?: boolean
  readonly color?: string
  readonly danger?: boolean
  readonly className?: string
  containerChildren?: React.ReactNode
  readonly style?: React.CSSProperties
  autoFocus?: boolean
}

const Input = ({
  id = undefined,
  placeholder = " ",
  autoComplete,
  area = false,
  disabled,
  name = "",
  required,
  value,
  children,
  onChange,
  onBlur,
  onFocus,
  defaultValue,
  type = "text",
  rows,
  dense,
  danger = false,
  color,
  className,
  containerChildren,
  style,
  autoFocus = false,
}: InputProps) => {
  return (
    <TextfieldContainer dense={dense} danger={danger} color={color} className={className}>
      {!area && type !== "currency" && (
        <input
          id={id}
          type={type}
          placeholder={placeholder}
          autoComplete={autoComplete}
          disabled={disabled}
          name={name}
          required={required}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          defaultValue={defaultValue}
          style={style}
          autoFocus={autoFocus}
        />
      )}
      {!area && type === "currency" && typeof value !== "number" && (
        <CleverCurrencyField
          id={id}
          placeholder={placeholder}
          disabled={disabled}
          name={name}
          required={required}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          defaultValue={defaultValue}
        />
      )}
      {area && (
        <textarea
          id={id}
          placeholder={placeholder}
          disabled={disabled}
          name={name}
          required={required}
          value={value}
          onChange={onChange as React.ChangeEventHandler<HTMLTextAreaElement> | undefined}
          onBlur={onBlur as React.FocusEventHandler<HTMLTextAreaElement> | undefined}
          onFocus={onFocus as React.FocusEventHandler<HTMLTextAreaElement> | undefined}
          defaultValue={defaultValue}
          rows={rows}
          autoFocus={autoFocus}
        />
      )}
      <label>{children}</label> {containerChildren}
    </TextfieldContainer>
  )
}

export default Input
